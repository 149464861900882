import React from 'react';
import { connect } from 'react-redux';
import Helper from '../../modules/Helper';
import EmailConfirmation from './EmailConfirmation';

type ConfirmationProps = {
	onlyMobile?: boolean;
	isBrowserSupportNotification?: boolean;
	hideOnMobile?: boolean;
	priceAlert: string[];
};
type ConfirmationState = {
	isMobile: boolean;
};
class Confirmation extends React.Component<ConfirmationProps, ConfirmationState> {
	constructor(props: ConfirmationProps) {
		super(props);
		this.state = {
			isMobile: false,
		};
		if (typeof window != 'undefined') {
			window.addEventListener('resize', this.checkWindowSize);
		}
	}

	componentDidMount() {
		if (typeof window != 'undefined') {
			this.checkWindowSize();
		}
	}

	componentWillUnmount() {
		window.removeEventListener('resize', this.checkWindowSize);
	}

	checkWindowSize = () => {
		const width = window.innerWidth;
		this.setState({
			isMobile: width < 1200,
		});
	};

	render() {
		const isMobile = this.state.isMobile;
		if (this.props.onlyMobile && !isMobile) {
			return <></>;
		}
		if (this.props.hideOnMobile && isMobile) {
			return <></>;
		}

		// @ts-ignore
		const REGISTER_PRICE_ALERTS = Helper.bool(process.env.NEXT_PUBLIC_REGISTER_PRICE_ALERTS);
		if (!REGISTER_PRICE_ALERTS && !Helper.isPa() && !Helper.isSa()) {
			return <></>;
		}

		const priceAlert = this.props.priceAlert;

		if (priceAlert.indexOf('email') !== -1) {
			return <EmailConfirmation isMobile={isMobile} />;
		}
		return <></>;
	}
}

// @ts-ignore
const mapStateToProps = (state) => ({
	priceAlert: state.base.priceAlert,
	isBrowserSupportNotification: state.base.isBrowserSupportNotification,
	random: state.base.random,
});
export default connect(mapStateToProps, {})(Confirmation);

import { useState } from 'react';
import InputMask from 'react-input-mask';

import Select from 'react-select';

import classnames from 'classnames';
import { useTranslation } from 'react-i18next';
import styles from './PhoneInput.module.css';

import { Icons } from 'app/components/Common/Icons';

const flagSize = {
	width: 27,
	height: 18,
};

const options = [
	{
		value: 'US',
		label: <Icons.USFlag width={flagSize.width} height={flagSize.height} />,
		code: '+1',
		mask: '+1 (999) 999-9999',
		country: 'United States',
	},
	{
		value: 'CA',
		label: <Icons.CAFlag width={flagSize.width} height={flagSize.height} />,
		code: '+1',
		mask: '+1 (999) 999-9999',
		country: 'Canada',
	},
	{
		value: 'GB',
		label: <Icons.GBFlag width={flagSize.width} height={flagSize.height} />,
		code: '+44',
		mask: '+44 9999 999999',
		country: 'United Kingdom',
	},
	{
		value: 'AU',
		label: <Icons.AUFlag width={flagSize.width} height={flagSize.height} />,
		code: '+61',
		mask: '+61 999 999 999',
		country: 'Australia',
	},
];

export function PhoneInput(props) {
	const { t } = useTranslation();
	const [country, setCountry] = useState(options[0]);
	const [isSelectOpen, setIsSelectOpen] = useState(false);
	const [phone, setPhone] = useState('');

	const { isMobile } = props;

	const handleChangeCountry = (selectedOption) => {
		setIsSelectOpen(false);
		setPhone('');
		setCountry(selectedOption);
	};

	const { onChange } = props;
	const handleChangePhone = (e) => {
		const phone = e.target.value.replace(/[^0-9+]/g, '');
		setPhone(phone);
		onChange(phone);
		console.log(phone);
	};

	return (
		<div
			className={classnames(`d-flex ${styles['input-wrapper']}`, 'mb-2', {
				'mt-0': isMobile,
			})}
			style={{ width: '100%' }}
		>
			<Select
				value={country}
				options={options}
				onChange={handleChangeCountry}
				isOptionSelected={(option) => option.value === country.value}
				styles={{
					option: (provided, state) => ({
						...provided,
						backgroundColor: state.isFocused ? '#e8f0fe' : 'white',
						'&:hover': {
							backgroundColor: '#aaa',
						},
					}),
					control: (provided) => ({
						...provided,
						height: '100%',
						cursor: 'pointer',
						border: 'none',
						'box-shadow': 'none',
					}),
					valueContainer: (provided) => ({
						...provided,
						width: '45px',
					}),
					indicatorsContainer: (provided) => ({
						...provided,
						display: 'none',
					}),
					menu: (provided) => ({
						...provided,
						width: '250px',
						padding: '2px 8px',
						'z-index': '9999',
						overflow: 'visible',
					}),
				}}
				getOptionLabel={(option) => <>{option.label}</>}
				getOptionValue={(option) => option.value}
				components={{
					Option: ({ data, ...props }) => (
						<div {...props} onClick={() => handleChangeCountry(data)} className="cursor-pointer py-1">
							{data.label} {data.country} <span className="text-brown-grey">{data.code}</span>
						</div>
					),
				}}
				menuIsOpen={isSelectOpen}
				onMenuOpen={() => setIsSelectOpen(true)}
				onMenuClose={() => setIsSelectOpen(false)}
				isSearchable={false}
			/>
			<InputMask
				mask={country.mask}
				maskChar="_"
				className={classnames('form-control my-0', {
					'cs-input': isMobile,
				})}
				value={phone}
				onChange={handleChangePhone}
				placeholder={`${t('keywords.phone')} (${t('keywords.optional')})`}
			/>
		</div>
	);
}

export default PhoneInput;

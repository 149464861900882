type IconProps = React.HTMLAttributes<SVGElement> & {
	fill?: string;
	width?: string;
	height?: string;
};

export const Icons = {
	USFlag: (props: IconProps) => (
		<svg width={props.width} height={props.height} xmlns="http://www.w3.org/2000/svg">
			<defs>
				<linearGradient x1="50%" y1="0%" x2="50%" y2="100%" id="a">
					<stop stop-color="#FFF" offset="0%" />
					<stop stop-color="#F0F0F0" offset="100%" />
				</linearGradient>
				<linearGradient x1="50%" y1="0%" x2="50%" y2="100%" id="b">
					<stop stop-color="#D02F44" offset="0%" />
					<stop stop-color="#B12537" offset="100%" />
				</linearGradient>
				<linearGradient x1="50%" y1="0%" x2="50%" y2="100%" id="c">
					<stop stop-color="#46467F" offset="0%" />
					<stop stop-color="#3C3C6D" offset="100%" />
				</linearGradient>
			</defs>
			<g fill="none" fill-rule="evenodd">
				<path fill="url(#a)" d="M0 0h21v15H0z" />
				<path
					d="M0 0h21v1H0V0Zm0 2h21v1H0V2Zm0 2h21v1H0V4Zm0 2h21v1H0V6Zm0 2h21v1H0V8Zm0 2h21v1H0v-1Zm0 2h21v1H0v-1Zm0 2h21v1H0v-1Z"
					fill="url(#b)"
				/>
				<path fill="url(#c)" d="M0 0h9v7H0z" />
				<path
					d="M1.5 2a.5.5 0 1 1 0-1 .5.5 0 0 1 0 1Zm2 0a.5.5 0 1 1 0-1 .5.5 0 0 1 0 1Zm2 0a.5.5 0 1 1 0-1 .5.5 0 0 1 0 1Zm2 0a.5.5 0 1 1 0-1 .5.5 0 0 1 0 1Zm-5 1a.5.5 0 1 1 0-1 .5.5 0 0 1 0 1Zm2 0a.5.5 0 1 1 0-1 .5.5 0 0 1 0 1Zm2 0a.5.5 0 1 1 0-1 .5.5 0 0 1 0 1Zm1 1a.5.5 0 1 1 0-1 .5.5 0 0 1 0 1Zm-2 0a.5.5 0 1 1 0-1 .5.5 0 0 1 0 1Zm-2 0a.5.5 0 1 1 0-1 .5.5 0 0 1 0 1Zm-2 0a.5.5 0 1 1 0-1 .5.5 0 0 1 0 1Zm1 1a.5.5 0 1 1 0-1 .5.5 0 0 1 0 1Zm2 0a.5.5 0 1 1 0-1 .5.5 0 0 1 0 1Zm2 0a.5.5 0 1 1 0-1 .5.5 0 0 1 0 1Zm1 1a.5.5 0 1 1 0-1 .5.5 0 0 1 0 1Zm-2 0a.5.5 0 1 1 0-1 .5.5 0 0 1 0 1Zm-2 0a.5.5 0 1 1 0-1 .5.5 0 0 1 0 1Zm-2 0a.5.5 0 1 1 0-1 .5.5 0 0 1 0 1Z"
					fill="url(#a)"
				/>
			</g>
		</svg>
	),
	GBFlag: (props: IconProps) => (
		<svg width={props.width} height={props.height} xmlns="http://www.w3.org/2000/svg">
			<defs>
				<linearGradient x1="50%" y1="0%" x2="50%" y2="100%" id="a">
					<stop stop-color="#FFF" offset="0%" />
					<stop stop-color="#F0F0F0" offset="100%" />
				</linearGradient>
				<linearGradient x1="50%" y1="0%" x2="50%" y2="100%" id="b">
					<stop stop-color="#0A17A7" offset="0%" />
					<stop stop-color="#030E88" offset="100%" />
				</linearGradient>
				<linearGradient x1="50%" y1="0%" x2="50%" y2="100%" id="c">
					<stop stop-color="#E6273E" offset="0%" />
					<stop stop-color="#CF152B" offset="100%" />
				</linearGradient>
			</defs>
			<g fill="none" fill-rule="evenodd">
				<path fill="url(#a)" d="M0 0h21v15H0z" />
				<path fill="url(#b)" d="M-.002 0h21v15h-21z" />
				<path
					d="M5.003 10H-.002V5h5.005L-2.082.22l1.118-1.657 8.962 6.045V-1h5v5.608l8.962-6.045L23.078.22 15.993 5h5.005v5h-5.005l7.085 4.78-1.118 1.657-8.962-6.045V16h-5v-5.608l-8.962 6.045-1.118-1.658L5.003 10Z"
					fill="url(#a)"
				/>
				<path
					d="m14.136 4.958 9.5-6.25a.25.25 0 0 0-.275-.417l-9.5 6.25a.25.25 0 1 0 .275.417Zm.732 5.522 8.515 5.74a.25.25 0 1 0 .28-.415l-8.516-5.74a.25.25 0 0 0-.279.415ZM6.142 4.526-2.74-1.461a.25.25 0 0 0-.28.415L5.863 4.94a.25.25 0 0 0 .279-.414Zm.685 5.469-9.845 6.53a.25.25 0 1 0 .276.416l9.846-6.529a.25.25 0 0 0-.277-.417Z"
					fill="#DB1F35"
					fill-rule="nonzero"
				/>
				<path fill="url(#c)" d="M-.002 9h9v6h3V9h9V6h-9V0h-3v6h-9z" />
			</g>
		</svg>
	),
	CAFlag: (props: IconProps) => (
		<svg width={props.width} height={props.height} xmlns="http://www.w3.org/2000/svg">
			<defs>
				<linearGradient x1="50%" y1="0%" x2="50%" y2="100%" id="a">
					<stop stop-color="#FFF" offset="0%" />
					<stop stop-color="#F0F0F0" offset="100%" />
				</linearGradient>
				<linearGradient x1="50%" y1="0%" x2="50%" y2="100%" id="b">
					<stop stop-color="#FF3131" offset="0%" />
					<stop stop-color="red" offset="100%" />
				</linearGradient>
			</defs>
			<g fill="none" fill-rule="evenodd">
				<path fill="url(#a)" d="M0 0h21v15H0z" />
				<path fill="url(#b)" d="M10 0h11v15H10zM0 0h7v15H0z" />
				<path fill="url(#a)" d="M6 0h9v15H6z" />
				<path
					d="m11.674 7.326.652-.652a.251.251 0 0 1 .355.007L13 7l1-.5-.5 1 .32.32a.247.247 0 0 1 .008.352L12.5 9.5H11l-.25 1.5h-.5L10 9.5H8.5L7.172 8.172a.253.253 0 0 1 .009-.353L7.5 7.5l-.5-1L8 7l.32-.32a.249.249 0 0 1 .354-.006l.652.652c.096.096.152.063.124-.075L9 5l1 .5.5-1.5.5 1.5 1-.5-.45 2.251c-.029.142.028.171.124.075Z"
					fill="url(#b)"
				/>
			</g>
		</svg>
	),
	AUFlag: (props: IconProps) => (
		<svg width={props.width} height={props.height} xmlns="http://www.w3.org/2000/svg">
			<defs>
				<linearGradient x1="50%" y1="0%" x2="50%" y2="100%" id="a">
					<stop stop-color="#FFF" offset="0%" />
					<stop stop-color="#F0F0F0" offset="100%" />
				</linearGradient>
				<linearGradient x1="50%" y1="0%" x2="50%" y2="100%" id="b">
					<stop stop-color="#0A17A7" offset="0%" />
					<stop stop-color="#030E88" offset="100%" />
				</linearGradient>
				<linearGradient x1="50%" y1="0%" x2="50%" y2="100%" id="c">
					<stop stop-color="#DB1E36" offset="0%" />
					<stop stop-color="#D51931" offset="100%" />
				</linearGradient>
			</defs>
			<g fill="none" fill-rule="evenodd">
				<path fill="url(#a)" d="M0 0h21v15H0z" />
				<path fill="url(#b)" d="M0 0h21v15H0z" />
				<path
					d="M3 3.23-1.352-.5H.66L4.16 2h.697L9.5-.902V.25c0 .303-.167.627-.418.806L6 3.257v.513l3.137 2.69c.462.395.204 1.04-.387 1.04-.245 0-.545-.096-.75-.242L4.84 5h-.697L-.5 7.902v-1.66l3.5-2.5V3.23Z"
					fill="url(#a)"
					fill-rule="nonzero"
				/>
				<path
					d="M3.5 3 0 0h.5L4 2.5h1L9 0v.25a.537.537 0 0 1-.208.399L5.5 3v1l3.312 2.839c.104.089.072.161-.062.161a.898.898 0 0 1-.458-.149L5 4.5H4L0 7v-.5L3.5 4V3Z"
					fill="url(#c)"
				/>
				<path
					d="M0 2.5v2h3.5v2.505c0 .273.214.495.505.495h.99a.496.496 0 0 0 .505-.495V4.5h3.51a.49.49 0 0 0 .49-.505v-.99a.495.495 0 0 0-.49-.505H5.5V0h-2v2.5H0Z"
					fill="url(#a)"
				/>
				<path fill="url(#c)" d="M0 3h4V0h1v3h4v1H5v3H4V4H0z" />
				<path
					fill="#FFF"
					d="m4.5 12.25-.882.464.169-.982-.714-.696.986-.143L4.5 10l.44.893.987.143-.714.696.169.982zM15 13l-.707.207.207-.707-.207-.707L15 12l.707-.207-.207.707.207.707zm0-9.5-.707.207L14.5 3l-.207-.707L15 2.5l.707-.207L15.5 3l.207.707zm3 3-.707.207L17.5 6l-.207-.707L18 5.5l.707-.207L18.5 6l.207.707zm-6 1-.707.207L11.5 7l-.207-.707L12 6.5l.707-.207L12.5 7l.207.707zm4.5 1.25-.354.104.104-.354-.104-.354.354.104.354-.104-.104.354.104.354z"
				/>
			</g>
		</svg>
	),
	email: (props: IconProps) => (
		<svg
			fill="#6d6d6d"
			height="26px"
			width="26px"
			version="1.1"
			id="Layer_1"
			xmlns="http://www.w3.org/2000/svg"
			viewBox="0 0 511.894 511.894"
			{...props}
		>
			<path d="M477.973,53.28H34.027C15.253,53.28,0,68.534,0,87.307v337.28c0,18.773,15.253,34.027,34.027,34.027h443.84 c18.773,0,34.027-15.253,34.027-34.027V87.307C512,68.534,496.747,53.28,477.973,53.28z M475.733,74.614L270.72,283.147 c-8.107,7.893-21.013,8-29.227,0.213L36.267,74.614H475.733z M21.333,422.667v-332.8l169.92,172.8L21.333,422.667z M36.907,437.28 l169.387-159.36l20.16,20.587c7.893,7.68,18.453,12.053,29.44,12.053c11.093,0,21.867-4.373,29.76-12.16l15.147-15.467 L474.667,437.28H36.907z M490.667,423.094L315.84,267.574L490.667,89.867V423.094z"></path>{' '}
		</svg>
	),
	plus: (props: IconProps) => (
		<svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
			<path d="M16 9V23M9 16H23" stroke="#979797" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
  </svg>
	),
	minus: (props: IconProps) => (
		<svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
			<path d="M9 16H23" stroke="#333333" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
  </svg>

	)
};

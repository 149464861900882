import { TooltipErrors } from 'app/components/Common/BaseTooltipError';
import classnames from 'classnames';
import React, { FC, ReactNode, useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import FadeTransition from './FadeTransition';

type TooltipErrorProps = {
	name: string;
	label?: string;
	labelClass?: string;
	openedInput?: string;
	tooltipErrors: TooltipErrors;
	flexOne?: boolean;
	showErrors?: boolean;
	children: ReactNode;
};

const TooltipError: FC<TooltipErrorProps> = (props) => {
	const { name, label, labelClass, openedInput, tooltipErrors, flexOne, showErrors, children } = props;
	const error = tooltipErrors[name];
	const tooltipErrorRef = useRef<HTMLDivElement>(null);

	const childrenWithProps = React.Children.map(children, (child) => {
		if (child) {
			// @ts-ignore
			return React.cloneElement(child, { preventSetInputBoxClassName: true });
		}
		return child;
	});

	useEffect(() => {
		const tooltip = tooltipErrorRef.current;

		if (tooltip) {
			if (tooltip.offsetHeight > 35 && tooltip.offsetHeight < 70) {
				tooltip.style.setProperty('--tooltip-error-height', `-18px`);
			} else if (tooltip.offsetHeight > 70) {
				tooltip.style.setProperty('--tooltip-error-height', `-41px`);
			} else {
				tooltipErrorRef.current.style.setProperty('--tooltip-error-height', `6px`);
			}
		}
	});

	return (
		<div
			id={`${name}-parent`}
			className={classnames('input-items', {
				'flex-1': flexOne,
			})}
		>
			{label ? <label className={labelClass}>{label}</label> : ''}
			<div className="input-group">
				<div
					className={classnames('input-box', {
						'open-input': name === openedInput,
					})}
				>
					<FadeTransition timeout={1003} in={error !== undefined && showErrors}>
						<div className="tooltip-error" ref={tooltipErrorRef}>
							{error}
						</div>
					</FadeTransition>
					{childrenWithProps}
				</div>
			</div>
		</div>
	);
};

const mapStateToProps = (state: any) => ({
	tooltipErrors: state.base.tooltipErrors,
	showErrors: state.base.showErrors,
	openedInput: state.base.openedInput,
});

export default connect(mapStateToProps, {})(TooltipError);

import { Icons } from 'app/components/Common/Icons';
import classNames from 'classnames';
import { ChangeEvent, FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import Switch from 'react-switch';
import { setPriceAlertEmail, setPriceAlertPhone, togglePriceAlertHandler } from '../../../actions/baseActions';
import Helper from '../../modules/Helper';
import BaseTooltipError from '../Common/BaseTooltipError';
import PhoneInput from './ModalSignUp/PhoneInput';

type EmailConfirmationProps = {
	email: string;
	isMobile: boolean;
	lng: string;
	setPriceAlertEmail: (email: string) => void;
	setPriceAlertPhone: (phone: string) => void;
	togglePriceAlertHandler: (toggle: boolean) => void;
};

const EmailConfirmation: FC<EmailConfirmationProps> = (props) => {
	const { t } = useTranslation();
	const { isMobile } = props;
	const [isChecked, setIsChecked] = useState(false);
	const isRtl = Helper.isRtlLanguage(props.lng);

	const onChangeEmail = (e: ChangeEvent<HTMLInputElement>) => {
		props.setPriceAlertEmail(e.target.value);
	};
	const onChangePhone = (phone: string) => {
		props.setPriceAlertPhone(phone);
	};
	const handleChange = (checked: boolean) => {
		props.togglePriceAlertHandler(checked);
		setIsChecked(checked);
	};
	const getEmailInput = () => {
		return (
			<input
				placeholder={t('priceAlert.emailPlaceholder')}
				autoComplete="email"
				name="email"
				onChange={onChangeEmail}
				value={props.email}
				type="email"
				className="form-control cs-input"
			/>
		);
	};
	const getPhoneInput = () => {
		return (
			<BaseTooltipError className="input-group" name="price-alert-phone">
				<div id="price-alert-phone-container" className="input-box mr-0 ml-0">
					<div style={{ width: '100%', overflow: 'visible' }} className="input-control">
						<PhoneInput isMobile={true} onChange={onChangePhone} />
					</div>
				</div>
			</BaseTooltipError>
		);
	};

	if (isMobile) {
		return (
			<div
				className={classNames('checkbox-wrapper col-12', {
					'rtl-direction': isRtl,
				})}
			>
				<Switch
					checked={isChecked}
					onChange={handleChange}
					onColor="#cdebf7"
					onHandleColor="#049dd9"
					handleDiameter={20}
					uncheckedIcon={false}
					checkedIcon={false}
					boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
					activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
					height={16}
					width={34}
					className="react-switch me-2"
					id="material-switch"
				/>
				<span
					id="material-switch-label"
					onClick={() => handleChange(!isChecked)}
					className={classNames('cs-label', {
						'me-3': isRtl,
					})}
				>
					{t('priceAlert.label')}
				</span>
				{/* <UnmountClosed isOpened={isChecked}> */}
				{isChecked && (
					<>
						<BaseTooltipError className="input-group" name="price-alert-email">
							<div id="price-alert-email-container" className="input-box mr-0 ml-0">
								<div style={{ width: '100%' }} className="input-control">
									<div className="icon-mail">
										<Icons.email />
									</div>
									{getEmailInput()}
								</div>
							</div>
						</BaseTooltipError>
						{Helper.isSa() ? getPhoneInput() : null}
					</>
				)}
				{/* </UnmountClosed> */}
			</div>
		);
	}
	return (
		<div className="row">
			<BaseTooltipError className="col-md-4" name="price-alert-email">
				<label className="cs-label">{t('priceAlert.label')}</label>
				{getEmailInput()}
			</BaseTooltipError>
		</div>
	);
};

const mapStateToProps = (state: any) => ({
	email: state.base.priceAlertEmail,
	lng: state.base.lng,
});
export default connect(mapStateToProps, { setPriceAlertEmail, setPriceAlertPhone, togglePriceAlertHandler })(
	EmailConfirmation
);

import { FC } from 'react';

const IconCruises: FC<React.HTMLAttributes<HTMLDivElement>> = (props) => {
	return (
		<div {...props}>
			<svg width="20" height="22" viewBox="0 0 20 22" fill="none" xmlns="http://www.w3.org/2000/svg">
				<g opacity={0.7}>
					<path
						d="M1.9 18L0.05 11.575C-0.0166667 11.375 -0.00416667 11.1792 0.0875 10.9875C0.179167 10.7958 0.325 10.6667 0.525 10.6L2 10.1V4.5C2 4.08333 2.14583 3.73333 2.4375 3.45C2.72917 3.16667 3.08333 3.01667 3.5 3H7.25V0H12.75V3H16.5C16.9125 3 17.2656 3.14687 17.5594 3.44062C17.8531 3.73438 18 4.0875 18 4.5V10.125L19.5 10.6C19.6833 10.6667 19.8208 10.7917 19.9125 10.975C20.0042 11.1583 20.0167 11.35 19.95 11.55L18.1 18C17.3 17.9167 16.5542 17.675 15.8625 17.275C15.1708 16.875 14.5583 16.4 14.025 15.85C13.4917 16.45 12.886 16.9583 12.2079 17.375C11.5298 17.7917 10.7938 18 10 18C9.20615 18 8.47019 17.7917 7.79213 17.375C7.11404 16.9583 6.50833 16.45 5.975 15.85C5.44167 16.4 4.82917 16.875 4.1375 17.275C3.44583 17.675 2.7 17.9167 1.9 18ZM0 22V20.5H2C2.7 20.5 3.39167 20.3917 4.075 20.175C4.75833 19.9583 5.4 19.65 6 19.25C6.6 19.65 7.24167 19.95 7.925 20.15C8.60833 20.35 9.3 20.45 10 20.45C10.7 20.45 11.3917 20.35 12.075 20.15C12.7583 19.95 13.4 19.65 14 19.25C14.6 19.65 15.2417 19.9583 15.925 20.175C16.6083 20.3917 17.3 20.5 18 20.5H20V22H18C17.3167 22 16.6417 21.9125 15.975 21.7375C15.3083 21.5625 14.65 21.3083 14 20.975C13.35 21.3083 12.6833 21.5625 12 21.7375C11.3167 21.9125 10.65 22 10 22C9.35 22 8.68333 21.9125 8 21.7375C7.31667 21.5625 6.65 21.3083 6 20.975C5.35 21.3083 4.69332 21.5625 4.02995 21.7375C3.36658 21.9125 2.68663 22 1.9901 22H0ZM3.5 9.625L10 7.525L16.5 9.65V4.5H3.5V9.625ZM9.9875 16.5C10.8458 16.5 11.6083 16.1583 12.275 15.475C12.9417 14.7917 13.5167 14.1583 14 13.575C14.4167 14.075 14.8792 14.5625 15.3875 15.0375C15.8958 15.5125 16.45 15.925 17.05 16.275L18.325 11.8L10 9.1L1.65 11.8L2.95 16.225C3.53333 15.9083 4.0875 15.5125 4.6125 15.0375C5.1375 14.5625 5.6 14.075 6 13.575C6.5 14.175 7.075 14.8125 7.725 15.4875C8.375 16.1625 9.12917 16.5 9.9875 16.5Z"
						fill="white"
					/>
					<path
						d="M9.9875 16.5C10.8458 16.5 11.6083 16.1583 12.275 15.475C12.9417 14.7917 13.5167 14.1583 14 13.575C14.4167 14.075 14.8792 14.5625 15.3875 15.0375C15.8958 15.5125 16.45 15.925 17.05 16.275L18.325 11.8L10 9.1L1.65 11.8L2.95 16.225C3.53333 15.9083 4.0875 15.5125 4.6125 15.0375C5.1375 14.5625 5.6 14.075 6 13.575C6.5 14.175 7.075 14.8125 7.725 15.4875C8.375 16.1625 9.12917 16.5 9.9875 16.5Z"
						fill="white"
					/>
				</g>
			</svg>
		</div>
	);
};
export default IconCruises;

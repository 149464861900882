import { saveFrontFormDataToSessionStorage } from 'actions/baseActions.utils';
import { format, formatISO, lastDayOfMonth, parse } from 'date-fns';
import { cruiseDurations } from 'reducers/cruisesReducer';
import { catchErrorSync } from '../app/components/Common/AppErrorBoundary';
import Helper from '../app/modules/Helper';
import store from '../store';
import { getFbClientId } from '../utils/cookies';
import {
	emitGMTEvent,
	hideAndShowErrors,
	sendDataToPassthruForm,
	setTicketData,
	setTooltipError,
	submitForm,
	togglePriceAlertSignUpModal,
	toggleProgressLoading,
} from './baseActions';
import { setErrorForPriceAlertEmail, setErrorForPriceAlertPhone } from './error-checks';
import {
	CRUISE_SELECT_DEPARTURE_DATE,
	CRUISE_SELECT_DESTINATION,
	CRUISE_SELECT_DURATION,
	CRUISE_SELECT_PASSENGERS,
} from './types';

export const selectDepartureDate = (date) => {
	const parsedDate = date.map((item) => {
		return parse(item, 'MMMM, yyyy', new Date());
	});

	return catchErrorSync((dispatch) => {
		dispatch({
			type: CRUISE_SELECT_DEPARTURE_DATE,
			payload: parsedDate,
		});
	});
};

export const selectCruiseDestination = (destination) => {
	return catchErrorSync((dispatch) => {
		dispatch({
			type: CRUISE_SELECT_DESTINATION,
			payload: destination,
		});
	});
};

export const selectCruiseDuration = (duration) => {
	return catchErrorSync((dispatch) => {
		dispatch({
			type: CRUISE_SELECT_DURATION,
			payload: duration,
		});
	});
};

export const selectCruisePassengers = (passengers) => {
	return catchErrorSync((dispatch) => {
		dispatch({
			type: CRUISE_SELECT_PASSENGERS,
			payload: passengers,
		});

		const childrenAgesValues = passengers.childrenAges.filter((item) => item?.value !== undefined);
		if (passengers.children === 0 || (passengers.children > 0 && passengers.children === childrenAgesValues.length)) {
			const errors = store.getState().base.tooltipErrors;
			delete errors['cruise-passengers'];
			store.dispatch(setTooltipError(errors));
		}
	});
};

export const doSearchCruises = () => {
	return catchErrorSync(() => {
		const cruiseState = store.getState().cruises;
		const baseState = store.getState().base;
		const errors = {};

		setErrorForPriceAlertEmail(errors);
		setErrorForPriceAlertPhone(errors);

		if (!cruiseState.cruiseDestination.value.length) {
			errors['cruise-destination'] = 'Please select or enter a destination';
		}

		const childrenAgesValues = cruiseState.passengers.childrenAges.filter((item) => item?.value !== undefined);

		if (cruiseState.passengers.children > 0 && childrenAgesValues.length !== cruiseState.passengers.children) {
			errors['cruise-passengers'] = 'Please add appropriate ages for children';
		}
		store.dispatch(setTooltipError(errors));

		if (
			Helper.isEmpty(errors) &&
			!baseState.isClosedPriceAlertSignUpModal &&
			baseState.priceAlertHandlerChecked &&
			document.body.clientWidth >= 992
		) {
			store.dispatch(togglePriceAlertSignUpModal(true));
			return;
		}
		if (Helper.isEmpty(errors)) {
			store.dispatch(emitGMTEvent(store.getState().base.enabledVert));
			const parsed = Helper.parseQueryString(window.location.search, true);
			let preventOpenFrontTab = false;

			if (Helper.hasProperty(parsed, 'f') && parsed.f === '0') {
				preventOpenFrontTab = true;
			}

			if (Helper.hasProperty(parsed, 'autoredirect') && parsed.autoredirect === '1') {
				preventOpenFrontTab = true;
			}
			let openBackTabTimeout = 500;

			if (preventOpenFrontTab) {
				openBackTabTimeout = 100;
			}

			setTimeout(() => {
				saveFrontFormDataToSessionStorage(store.dispatch(getFormData('front')));

				if (!preventOpenFrontTab) {
					store.dispatch(submitFormFront());
				}

				setTimeout(() => {
					store.dispatch(toggleProgressLoading(true));
					store.dispatch(submitFormBack());
				}, openBackTabTimeout);
			}, 10);
		} else {
			store.dispatch(hideAndShowErrors('.cruises-filter-form'));
		}
	});
};

export const submitFormBack = () => {
	return catchErrorSync(() => {
		const form = document.getElementById('redirect-back-form');
		const data = store.dispatch(getFormData('back'));
		store.dispatch(setTicketData(data));
		store.dispatch(submitForm(form, data));
	});
};

export const submitFormFront = () => {
	return catchErrorSync(() => {
		store.dispatch(sendDataToPassthruForm(store.dispatch(getFormData('front'))));
	});
};

export const getFormData = (formType) => {
	return catchErrorSync(() => {
		const data = store.getState().cruises;
		const { cruiseDestination, departureDate, cruiseDuration, passengers } = data;
		const durationStart = cruiseDuration.value.split('-')[0];
		const durationEnd = cruiseDuration.value.split('-')[1];
		const departureStart = departureDate[0];
		const departureStartISOString = Helper.isDateAfter(departureStart)
			? formatISO(new Date())
			: formatISO(departureStart);
		const lastDepartureMonth = new Date(departureDate[departureDate.length - 1]);
		const departureEnd = lastDepartureMonth
			? formatISO(lastDayOfMonth(lastDepartureMonth))
			: formatISO(lastDayOfMonth(departureStart));

		const vert = store.getState().base.enabledVert;
		const fbp = getFbClientId();
		const childrenAgesValues = data.passengers.childrenAges
			.filter((item) => item?.value !== undefined)
			.map((item) => item.value);

		const formData = {
			tab: formType,
			destination: cruiseDestination.value,
			'cruise-departure-start': departureStartISOString,
			'cruise-departure-end': departureEnd,
			'cruise-length-min': durationStart,
			'cruise-length-max': durationEnd,
			'cruise-adults': passengers.adults,
			'cruise-children': passengers.children,
			'child-ages': childrenAgesValues.join(','),
			vert,
			fbp: fbp ? fbp : '',
		};
		const email = store.getState().base.priceAlertEmail;

		if (email) {
			formData['email'] = email;
		}

		const phone = store.getState().base.priceAlertPhone;

		if (phone) {
			formData['phone'] = phone;
		}
		return formData;
	});
};

export const prePopulateDataFromQueryString = () => {
	return catchErrorSync(() => {
		const parsed = Helper.parseQueryString(window.location.search, true);

		if (Helper.hasProperty(parsed, 'destination') && parsed['destination']) {
			const destination = parsed['destination'];
			store.dispatch(selectCruiseDestination({ value: destination }));
		}

		if (Helper.hasProperty(parsed, 'cruise-departure-start') && parsed['cruise-departure-start']) {
			const departureStart = format(new Date(parsed['cruise-departure-start']), 'MMM, yyyy');
			let departureEnd = '';
			store.dispatch(selectDepartureDate([departureStart]));
			if (Helper.hasProperty(parsed, 'cruise-departure-end') && parsed['cruise-departure-end']) {
				departureEnd = format(new Date(parsed['cruise-departure-end']), 'MMM, yyyy');
				store.dispatch(selectDepartureDate([departureStart, departureEnd]));
			}
		}

		if (
			Helper.hasProperty(parsed, 'cruise-length-min') &&
			parsed['cruise-length-min'] &&
			Helper.hasProperty(parsed, 'cruise-length-max') &&
			parsed['cruise-length-max']
		) {
			const durationStart = parsed['cruise-length-min'];
			const durationEnd = parsed['cruise-length-max'];
			const duration = `${durationStart}-${durationEnd}`;

			Object.values(cruiseDurations).find((item) => {
				if (item.value === duration) {
					store.dispatch(selectCruiseDuration(item));
					return true;
				}
			});
		}

		if (Helper.hasProperty(parsed, 'cruise-adults') && parsed['cruise-adults']) {
			const adults = +parsed['cruise-adults'];
			const passengers = store.getState().cruises.passengers;
			store.dispatch(selectCruisePassengers({ children: +passengers.children, adults }));

			if (Helper.hasProperty(parsed, 'cruise-children') && parsed['cruise-children']) {
				const children = +parsed['cruise-children'];

				store.dispatch(selectCruisePassengers({ adults, children, childrenAges: [] }));
			}
		}
	});
};
